<template>
  <div class="Favorite">
    <div class="giao">
      <div class="tabs">
        <div class="handle">
          <ul v-if="index==0">
            <li v-if='handle'>
              <el-checkbox v-model="all_checked" @change="all"></el-checkbox>全选
            </li>
            <li v-if='handle' @click="del"><i class="el-icon-delete"></i>删除</li>

            <li>
              <el-button type="info" v-if='!handle' @click="handle=1">批量操作</el-button>
              <el-button type="info" v-else @click="handle=0">取消操作</el-button>
            </li>
          </ul>
          <ul v-else>
            <li v-if='handle2'>
              <el-checkbox v-model="all_checked2" @change="all2"></el-checkbox>全选
            </li>
            <li v-if='handle2' @click="del2"><i class="el-icon-delete"></i>删除</li>

            <li>
              <el-button type="info" v-if='!handle2' @click="handle2=1">批量操作</el-button>
              <el-button type="info" v-else @click="handle2=0">取消操作</el-button>
            </li>
          </ul>
        </div>
        <el-tabs v-model="activeName" @tab-click="tabClick">
          <el-tab-pane label="收藏的商品" name="0">

            <div class="goods">

              <div class="List" v-for="(item,index) in collectList" :key='index'>
                <div class="cover1" :class="{cover2: item.checked}" @click="item.checked=!item.checked" v-if="handle">
                  <p> <i class="el-icon-check" style=" color:#fff"></i></p>
                </div>

                <div class="goods">
                  <div class="img_box">

                    <i class="el-icon-delete cover3 cover3_t" @click="delGoods(item.goods_id)"> </i>
                    <p class="  cover3 cover3_b hover" @click="$util.toShop(item.shop_type, item.shop_id )">进入店铺 </p>

                    <img @click="$util.toDetail(item.shop_type, item.goods_id )" :src="baseUrl + item.files_path" alt="" class="List_li" />
                  </div>
                  <div class="List_div">{{ item.goods_name }}</div>
                  <div
                  class="item-price"
                  v-if="item.isprice == 2"
                >
                  <span>￥{{ parseFloat(item.min_price).toFixed(2) }}</span>
                  -
                  <span>￥{{ parseFloat(item.max_price).toFixed(2) }}</span>
                </div>

                <div
                  class="item-price"
                  v-if="item.isonly == 1 && item.isprice == 1"
                >
                  <span>￥{{
                        parseFloat(item.sales_price/ 100).toFixed(2) 
                      }}</span>
                </div>
                <div
                  class="item-price"
                  v-if="item.isonly == 2 && item.isprice == 1"
                >
                  <span>￥{{ item.spec_min_price }} 起</span>
                </div>
                </div>

              </div>
              <p v-if="collectList == ''">暂无收藏商品</p>
            </div>
          </el-tab-pane>
          <el-tab-pane label="收藏的店铺" name="1">

            <div class="collectshop" v-for="(item, index) in collectshop" :key="index">
              <div class="cover1_1" :class="{cover2_2: item.checked}" @click="item.checked=!item.checked" v-if="handle2">
                <p> <i class="el-icon-check" style=" color:#fff"></i></p>
              </div>
              <el-row>
                <el-col :span="6" class="shop">
                  <div> <img :src="
                      item.shop_logo ?$config.baseUrl +shop_logo : defaultImg
                      
                    " @click="$util.toShop(item.shop_type,item.collect_shop_id)" />
                    <p> {{ item.collect_shop_name }}</p>
                    <div class="btn">

                      <el-tooltip class="item" effect="dark" content="删 除" placement="bottom">
                        <i class="el-icon-delete" @click='delShop(item.collect_shop_id)'>

                        </i>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" content="置 顶" placement="bottom">
                        <i class="el-icon-s-flag">

                        </i>
                      </el-tooltip>

                    </div>
                  </div>

                </el-col>
                <el-col v-if='item.get_shop[0].enabled' :span="18" class="pro">

                  <div class="img" :span="8" v-for="(pro, idx) in item.goods" :key="idx" @click="$util.toDetail(pro.shop_type, pro.goods_id )">

                    <img v-if="pro.good_url" :src="$config.baseUrl + pro.good_url" style="">
                    <p v-if="pro.good_url">{{ pro.goods_name }}</p>

                  </div>
                </el-col>

                <el-col v-else :span="18" class="pro ">
                  <div class=" close">
                    <img src="@/assets/images/close.jpg" alt="" />
                  </div>

                </el-col>

              </el-row>
            </div>

          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      index: 0,
      all_checked: false,
      all_checked2: false,
      activeName: "0",
      collectList: [],
      collectshop: [],
      baseUrl: this.$config.baseUrl,
      handle: 0,
      handle2: 0,
      defaultImg: require("@/assets/images/logo.png")
    };
  },
  methods: {
    


    tabClick(res) {
      console.log("res", res);

      this.index = res.index;
    },
    //删除单个商品
    delGoods(id) {
      let that = this;

      this.$confirm("确认删除选中商品吗？?", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$post("home/collect/del", {
          member_id: this.$store.state.member.id,
          goods_id: id
        }).then(res => {
          this.$message("删除成功");
          that.getCollect();
        });
      });
    },
    //删除单个店铺
    delShop(id) {
      let that = this;

      this.$confirm("确认删除选中店铺吗？?", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$post("home/collectshop/del", {
          member_id: this.$store.state.member.id,
          shop_id: id
        }).then(res => {
          console.log("collectshop/del", res);
          this.$message("删除成功");
          that.getCollectShop();
        });
      });
    },
    // 获取收藏
    getCollect() {
      this.$get("home/collect", { member_id: this.$store.state.member.id }).then(res => {
        this.collectList = res.items.data;
        console.log("collect", this.collectList);
        this.collectList.forEach(goods => {
          if (goods.checked === undefined) this.$set(goods, "checked", false);
        });
      });
    },
    // 获取关注店铺
    getCollectShop() {
      this.$get("home/collectshop", { member_id: this.$store.state.member.id }).then(res => {
        this.collectshop = res.items.data;
        this.collectshop.forEach(o => {
          this.$set(o, "goods", []);
          o.goods = o.get_goods.filter((item, index) => index < 4);
          if (o.checked === undefined) this.$set(o, "checked", false);
        });

        console.log(" goods", this.collectshop);
      });
    },
    all() {
      this.collectList.forEach(goods => {
        goods.checked = this.all_checked;
      });
    },
    all2() {
      this.collectshop.forEach(goods => {
        goods.checked = this.all_checked2;
      });
    },
    del() {
      let arr = this.collectList.filter(a => a.checked);
      if (!arr.length) {
        this.$message({
          type: "warning",
          message: "请先勾选对象"
        });
        return;
      }

      this.$confirm("确认删除选中对象?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.collectList.forEach((goods, index) => {
            if (goods.checked == true) {
              this.$post("home/collect/del", {
                member_id: this.$store.state.member.id,
                goods_id: goods.goods_id
              }).then(res => {
                console.log("collect/del", res);
                this.collectList.splice(index, 1);
              });
            }
          });
          this.close();
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    del2() {
      let arr = this.collectshop.filter(a => a.checked);
      if (!arr.length) {
        this.$message({
          type: "warning",
          message: "请先勾选对象"
        });
        return;
      }
      this.$confirm("确认删除选中对象?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.collectshop.forEach((goods, index) => {
            if (goods.checked == true) {
              this.$post("home/collectshop/del", {
                member_id: this.$store.state.member.id,
                shop_id: goods.get_shop[0].shop_id.toString()
              }).then(res => {
                console.log("collect/del", res);
                this.collectshop.splice(index, 1);
              });
            }
          });
          this.close();
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    }
  },
  created() {
    this.getCollect();
    this.getCollectShop();
    this.activeName = this.$route.query.id;
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";
.cover1_1 {
  height: 200px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  z-index: 100;
  position: absolute;
  border: 1px solid #757575;

  p {
    @include center;
    background: #757575;
    width: 30px;
    text-align: center;
    height: 30px;
    right: 0;
    top: 0px;
    overflow: hidden;
    position: absolute;
    text-align: right;
    cursor: pointer;
    border-bottom-left-radius: 5px;
  }
}

.cover2_2 {
  height: 200px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  z-index: 100;
  position: absolute;
  border: 1px solid #fe6925;

  p {
    @include center;
    background: #fe6925;
    width: 30px;
    text-align: center;
    height: 30px;
    right: 0;
    top: 0px;
    overflow: hidden;
    position: absolute;
    text-align: right;
    cursor: pointer;
    border-bottom-left-radius: 5px;
  }
}

.collectshop {
  border-bottom: 1px solid #eee;
  width: 100%;
  box-sizing: border-box;
  .shop {
    @include center;
    @include align-center;
    img {
      margin-top: 20px;
      width: 130px;
      width: 130px;
    }
    p {
      text-align: center;
      padding: 10px 0;
      width: 100%;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: bold;
    }

    .btn {
      text-align: center;
      i {
        font-size: 18px;
        margin: 20px;
        color: #999;
      }
    }
  }
  .pro {
    @include row;

    box-sizing: border-box;
    overflow: hidden;
    .img {
      text-align: center;
      width: 160px;
      margin-right: 20px;
    }
    img {
      width: 160px;
      box-sizing: border-box;
      height: 160px;
    }
    p {
      line-height: 30px;
      padding: 0 20px;
      height: 30px;
      @include ellipsis;
    }
    font-size: 12px;
    padding: 0 10px;
  }
}

.handle {
  width: 100%;
  text-align: right;
  li {
    display: inline-block;
    margin-left: 20px;
    letter-spacing: 5px;
  }
  li:hover {
    cursor: pointer;
  }
}
.handle2 {
  width: 100%;
  text-align: right;
  li:hover {
    cursor: pointer;
  }

  li {
    display: inline-block;
    margin-left: 20px;
    letter-spacing: 5px;
  }
}
.List {
  margin: 0 10px;
  height: 240px;
  width: 210px;
  float: left;
  overflow: hidden;
  box-sizing: border-box;

  .cover1 {
    height: 180px;
    width: 210px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    z-index: 100;
    position: absolute;
    border: 1px solid #757575;

    p {
      @include center;
      background: #757575;
      width: 30px;
      text-align: center;
      height: 30px;
      right: 0;
      top: 0px;
      overflow: hidden;
      position: absolute;
      text-align: right;
      cursor: pointer;
      border-bottom-left-radius: 5px;
    }
  }

  .cover2 {
    height: 180px;
    width: 210px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    z-index: 100;
    position: absolute;
    border: 1px solid #fe6925;

    p {
      @include center;
      background: #fe6925;
      width: 30px;
      text-align: center;
      height: 30px;
      right: 0;
      top: 0px;
      overflow: hidden;
      position: absolute;
      text-align: right;
      cursor: pointer;
      border-bottom-left-radius: 5px;
    }
  }

  .goods {
    width: 100%;

    box-sizing: border-box;
    background: #fff;
    position: relative;
    .img_box:hover {
      .cover3 {
        display: block;
      }
    }
    .img_box {
      .cover3 {
        display: none;
        z-index: 10;
        position: relative;

        color: #fff;
      }

      .cover3_t {
        padding-top: 10px;

        display: none;
        background: #fe6925;
        width: 30px;

        text-align: center;
        height: 30px;
        cursor: pointer;
        right: 0;
        top: 0;
        z-index: 10;
        position: absolute;
        border-bottom-left-radius: 5px;
      }
      .cover3_b {
        display: none;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 10;
        position: absolute;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 28px;
        line-height: 28px;
        text-align: center;
      }
      img {
        width: 100%;
        height: 100%;
        z-index: 0;
        position: absolute;
      }
      height: 180px;
      width: 210px;
      position: relative;
    }
  }
}

.List_ul:hover {
  border: 1px solid #eee;
}
.close {
  width: 100%;
  text-align: center !important;
  img {
    width: 150px;
  }
}
  .item-price {
          width: 100%;
          line-height: 20px;
          text-align: left;
          span {
            font-size: 14px;
            color: red;
            font-weight: bold;
            text-align: left;
          }
        }
</style>
